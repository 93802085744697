import Axios, { AxiosError, AxiosResponse } from 'axios'
import { TkResponse, TkResponseSuccess } from 'src/models/apiResponse'
import applyCaseMiddleware from 'axios-case-converter'
import { toast } from 'react-toastify'

export const getApiHost = (): string => {
  if (typeof window === 'undefined') {
    return ''
  }
  switch (window.location.hostname) {
    case 'localhost':
      return 'http://localhost:8080'
    case 'stg.tenken-kanrikun.itandibb.com':
      return 'https://api.stg.tenken-kanrikun.itandibb.com'
    case 'tenken-kanrikun.itandibb.com':
      return 'https://api.tenken-kanrikun.itandibb.com'
    default:
      throw new Error('Unknown host')
  }
}

export const ApiClient = applyCaseMiddleware(
  Axios.create({
    baseURL: `${getApiHost()}/tk`,
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
  }),
  { ignoreHeaders: true }
)

export const isSuccess = (
  response: Readonly<TkResponse<unknown, unknown>>
): response is TkResponseSuccess<unknown> => response.type === 'success'

const shouldMakeApiRequest = (error: AxiosError<unknown>): boolean => {
  // 特定の条件を確認し、APIリクエストを行うかどうか判断するロジックを実装する
  // 例: response.status === 401 (Unauthorized) の場合のみAPIリクエストを行う
  return error.response?.status === 401
}

type AuthenticationError = Readonly<{
  code: 'company' | 'user'
  message?: string
}>

type ErrorResponse = AuthenticationError | null

ApiClient.interceptors.response.use(
  (response: AxiosResponse<unknown>): AxiosResponse<unknown> => {
    return response
  },
  (error: Readonly<AxiosError<ErrorResponse>>): Promise<never> => {
    if (shouldMakeApiRequest(error)) {
      if (error.response?.data?.code === 'user') {
        ApiClient.get('/itandi_user_sessions/new').then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.location.replace(response.data.url)
        })
      } else {
        ApiClient.get('/itandi_account_sessions/new').then((response) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.location.replace(response.data.url)
        })
      }
    } else if (error.response?.status === 400) {
      toast.error(error.response.data?.message)
    }

    return Promise.reject(error)
  }
)
