import { useQuery } from 'react-query'
import { ApiClient } from '@utils/api'
import { Account } from './types'

type QueryParams = {
  building_name?: string
}

export const useAccounts = (params?: QueryParams) => {
  return useQuery<Account[]>('accounts', async () => {
    const response = await ApiClient.get('/accounts', { params })
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data
  })
}
