import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { ItandiHeader, ItandiHeaderItem } from '@itandi/itandi-bb-ui'
import { paths } from 'src/utils/paths'
import { useDeleteItandiAccountSessions } from '@hooks/useItandiAccountSessions'
import { useDeleteItandiAccountUserSessions } from '@hooks/useItandiAccountUserSessions'
import { useAccounts } from '@hooks/useAccounts'
import { ItandiAccounUsertSession } from '../models/ItandiAccountUserSession'
import { ItandiAccountSession } from '../models/ItandiAccountSession'

type HeaderItem = {
  label: string
  path: string
}

type props = {
  account: ItandiAccountSession
  user: ItandiAccounUsertSession
}

const headerItems: HeaderItem[] = [
  {
    label: '点検管理',
    path: paths.inspectionList,
  },
  {
    label: '建物管理契約',
    path: paths.contractList,
  },
  {
    label: '取引先管理',
    path: paths.vendorList,
  },
  {
    label: '担当者とロール',
    path: paths.roleList,
  },
]
export const Header: React.FC<props> = ({ account, user }) => {
  const router = useRouter()
  const { data: stores } = useAccounts()
  const isActivePage = useCallback(
    (path: string): boolean =>
      path === '/' ? router.pathname === '/' : router.pathname.includes(path),
    [router.pathname]
  )

  const onClickItem = useCallback(
    (path: string): void => {
      router.push(path)
    },
    [router]
  )

  const deleteItandiAccountSessions = useDeleteItandiAccountSessions()
  const deleteItandiAccountUserSessions = useDeleteItandiAccountUserSessions()

  const handleLogout = async () => {
    await deleteItandiAccountSessions.mutateAsync()
    router.push(`/contracts`)
  }
  const handleLogoutStaff = async () => {
    await deleteItandiAccountUserSessions.mutateAsync()
    router.push(`/contracts`)
  }
  const filteredAccounts = stores
    ? stores.map((store) => ({
        authId: store.authId,
        name: store.name,
      }))
    : []

  return (
    <ItandiHeader
      changeableStores={filteredAccounts}
      handleChangeStaff={handleLogoutStaff}
      handleLogout={handleLogout}
      itandiAccount={{
        companyName: `${account.companyName}`,
        storeName: `${account.storeName}`,
      }}
      productCode='tk'
      productParams={account.productParams}
      staffAccount={user}
    >
      {headerItems.map(({ label, path }) => (
        <ItandiHeaderItem
          key={path}
          active={isActivePage(path)}
          label={label}
          onClick={() => onClickItem(path)}
          url={path}
        />
      ))}
    </ItandiHeader>
  )
}
