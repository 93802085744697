import { z } from 'zod'

/**
 * Zodの日本語化のためのエラーメッセージ表示のmap
 * https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
 */
export const zodCustomErrorMap: z.ZodErrorMap = (error, ctx) => {
  // 該当しないエラーメッセージはctx.defaultErrorで標準のメッセージとする
  // eslint-disable-next-line default-case
  switch (error.code) {
    case z.ZodIssueCode.invalid_string:
      if (error.validation === 'email') {
        return { message: 'メールアドレスを入力してください' }
      }
      break
    case z.ZodIssueCode.too_small:
      if (error.type === 'string') {
        return {
          message: error.inclusive
            ? `少なくとも${error.minimum}文字以上で入力してください`
            : `${error.minimum}文字以上で入力してください`,
        }
      }

      if (error.type === 'number') {
        return {
          message: error.inclusive
            ? `${error.minimum}以上の数を入力してください`
            : `${error.minimum}より大きい数で入力してください`,
        }
      }
      break
    case z.ZodIssueCode.too_big:
      if (error.type === 'string') {
        return {
          message: error.inclusive
            ? `多くとも${error.maximum}文字以下で入力してください`
            : `${error.maximum}文字以下で入力してください`,
        }
      }

      if (error.type === 'number') {
        return {
          message: error.inclusive
            ? `${error.maximum}以下の数を入力してください`
            : `${error.maximum}より小さい数で入力してください`,
        }
      }
      break

    case z.ZodIssueCode.invalid_enum_value:
      return { message: '選択してください' }

    case z.ZodIssueCode.invalid_type:
      // invalid_type errorでvaludが nullは、必須項目が選択されていない場合である
      if (error.received === 'null') {
        return {
          message: '選択してください',
        }
      }
  }
  return { message: ctx.defaultError }
}
