import React from 'react'
import Head from 'next/head'

type Props = {
  title: string
  description: string
}

export const TkHead: React.FC<Props> = ({
  title,
  description,
}: Props): JSX.Element => {
  return (
    <Head>
      <title>{title}</title>
      <meta content={title} property='og:title' />
      <meta content={description} property='og:description' />
      <meta content='keyword' name='keywords' />
      <meta content='blog' property='og:type' />
      <meta content={title} property='og:site_name' />
    </Head>
  )
}
