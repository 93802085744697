type InspectionReportArgs = {
  inspectionId: ULID
}

type InspectionReportEditArgs = {
  inspectionId: ULID
  inspectionReportId: ULID
}

type InspectionArgs = {
  inspectionId: ULID
}

type InspectionNewArgs = {
  contractId: ULID
}

type InspectionEditArgs = {
  contractId: ULID
  inspectionTemplatetId: ULID
}

type ContractArgs = {
  contractId: ULID
}

type EquipmentArgs = {
  contractId: ULID
}

type vendorArgs = {
  vendorId: ULID
}

type roleArgs = {
  roleId: ULID
}

type Path = {
  top: string
  inspectionList: string
  // 点検報告
  inspectionReport: (args: InspectionReportArgs) => string
  // 点検報告編集
  inspectionReportEdit: (args: InspectionReportEditArgs) => string
  // 点検詳細
  inspectionSchedule: (args: InspectionArgs) => string
  // 点検登録
  inspectionNew: (args: InspectionNewArgs) => string
  // 点検編集
  inspectionEdit: (args: InspectionEditArgs) => string
  // 建物管理契約一覧
  contractList: string
  // 契約作成
  contractNew: string
  // 契約詳細
  contract: (args: ContractArgs) => string
  // 契約編集
  contractEdit: (args: ContractArgs) => string
  // 設備編集
  equipmentEdit: (args: EquipmentArgs) => string
  // 取引先
  vendorList: string
  // 契約編集
  vendorEdit: (args: vendorArgs) => string
  // 契約作成
  vendorNew: string
  // ロール
  roleList: string
  // ロール作成
  roleListNew: string
  // ロール編集
  roleEdit: (args: roleArgs) => string
  // ロール詳細
  role: (args: roleArgs) => string
}

export const paths: Path = {
  top: '/',
  inspectionList: '/inspectionSchedules',
  inspectionReport: ({ inspectionId }): string =>
    `/inspectionSchedules/${inspectionId}/inspectionReport`,
  inspectionReportEdit: ({ inspectionId, inspectionReportId }): string =>
    `/inspectionSchedules/${inspectionId}/inspectionReport/${inspectionReportId}`,
  inspectionSchedule: ({ inspectionId }): string =>
    `/inspectionSchedules/${inspectionId}`,
  inspectionNew: ({ contractId }): string =>
    `/contracts/${contractId}/inspection/new`,
  inspectionEdit: ({ contractId, inspectionTemplatetId }): string =>
    `/contracts/${contractId}/inspection/${inspectionTemplatetId}/edit`,
  contractList: '/contracts',
  contractNew: '/contracts/new',
  contract: ({ contractId }): string => `/contracts/${contractId}`,
  contractEdit: ({ contractId }): string => `/contracts/${contractId}/edit`,
  equipmentEdit: ({ contractId }): string =>
    `/contracts/${contractId}/equipment/edit`,
  vendorList: '/vendors',
  vendorNew: '/vendors/new',
  vendorEdit: ({ vendorId }): string => `/vendors/${vendorId}/edit`,
  roleList: '/roles',
  roleListNew: '/roles/new',
  roleEdit: ({ roleId }): string => `/roles/${roleId}/edit`,
  role: ({ roleId }): string => `/roles/${roleId}`,
}
