import { useQuery, useMutation } from 'react-query'
import { ApiClient } from '@utils/api'
import { ItandiAccounUsertSession } from '../models/ItandiAccountUserSession'

export const useItandiAccountUserSessions = () => {
  return useQuery<ItandiAccounUsertSession>(
    'itandi_user_sessions',
    async () => {
      const response = await ApiClient.get('/itandi_user_sessions')
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data
    }
  )
}

export const useDeleteItandiAccountUserSessions = () => {
  return useMutation(
    async () => {
      const response = await ApiClient.delete(`/itandi_user_sessions`)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data
    },
    {
      onSuccess: (data) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        window.location.replace(data.url)
      },
    }
  )
}
