import { useQuery, useMutation } from 'react-query'
import { ApiClient } from '@utils/api'
import { ItandiAccountSession } from '../models/ItandiAccountSession'

export const useItandiAccountSessions = () => {
  return useQuery<ItandiAccountSession>('itandi_account_sessions', async () => {
    const response = await ApiClient.get('/itandi_account_sessions')

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return response.data
  })
}

export const useDeleteItandiAccountSessions = () => {
  return useMutation(
    async () => {
      const response = await ApiClient.delete(`/itandi_account_sessions`)

      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return response.data
    },
    {
      onSuccess: (data: { url: string }) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        window.location.replace(data.url)
      },
    }
  )
}
